import { Module, MutationTree } from 'vuex';
import { RootStateInterface } from '@/store/rootState';
import { ClientsListStateInterface } from '../interfaces/ClientsListState';
import { Client } from '@/modules/clients/models/Client';

export const state: ClientsListStateInterface = {
    clients: [],
}

export const mutations: MutationTree<ClientsListStateInterface> = {
    setClients(oldState: ClientsListStateInterface, payload: Client[]) {
        oldState.clients = payload;
    },
} 

const namespaced: boolean = true;

export const clientsList: Module<ClientsListStateInterface, RootStateInterface> = {
    namespaced,
    state,
    mutations,
};