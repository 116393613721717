import { AppResponse } from 'modules/core/interfaces/appResponse';
import { WebSocketAction } from './../enums/WebSocketAction';
import { WebSocketRequest } from './WebSocketRequest';
import { WebSocketEventBus } from './WebSocketEventBus';
import environment from '@/environment';
import store from '@/store';
import { MortgageStatus } from '@/modules/mortgages/models/MortgageStatus';

export class WebSocketClient {
  protected dataBuffer: any[] = [];
  protected socket!: WebSocket;
  protected healthCheckInterval: any = undefined;
  protected readonly HEALTHCHECK_TIMEOUT: number = 60; // in seconds
  protected readonly RECONNECT_DELAY: number = 10; // in seconds

  public constructor() {
    WebSocketEventBus.$on(WebSocketAction.CHAT_SEND_MESSAGE, (message: any) => {
      this.sendMessage(message);
    });
    WebSocketEventBus.$on(
      WebSocketAction.MORTGAGE_UPDATE_STATUS,
      (status: MortgageStatus) => {
        this.updateStatus(status);
      },
    );

    WebSocketEventBus.$on(WebSocketAction.USER_LOGIN, () => {
      setTimeout(() => {
        if (
          store.state &&
          store.state.auth &&
          store.state.auth.user &&
          store.state.auth.user.id
        ) {
          this.send(
            new WebSocketRequest(WebSocketAction.CORE_INIT, {
              userId: store.state.auth.user.id,
            }),
          );
        }
      }, 100);
    });

    WebSocketEventBus.$on(WebSocketAction.USER_IMPERSONATE, (actingBrokerUserId: number) => {
      setTimeout(() => {
        this.send(
          new WebSocketRequest(WebSocketAction.CORE_INIT, {
            userId: actingBrokerUserId,
          }),
        );
      }, 100);
    });

    WebSocketEventBus.$on(WebSocketAction.USER_LOGOUT, () => {
      this.close();
    });
  }

  public sendMessage(msg: any) {
    const apiMessage = new WebSocketRequest(
      WebSocketAction.CHAT_SEND_MESSAGE,
      msg,
    );
    this.send(apiMessage);
  }

  public updateStatus(status: any) {
    const apiMessage = new WebSocketRequest(
      WebSocketAction.MORTGAGE_UPDATE_STATUS,
      status,
    );
    this.send(apiMessage);
  }

  public close() {
    /* tslint:disable-next-line  */
    this.socket.onclose = () => {}; // disable onclose handler first
    this.socket.close();
  }

  public initConnection() {
    // if WebSocket is working...
    if (this.isConnecting() || this.isOpen()) {
      return;
    }

    // @ts-ignore
    this.socket = null;
    this.socket = new WebSocket(environment.websocketUrl);

    // Connection opened
    this.socket.addEventListener('open', (event: Event) => {
      this.initializeHealthCheck();
      console.log('[WS] Connection started');

      /**
       * Init connection
       */
      if (
        store.state &&
        store.state.auth &&
        store.state.auth.user &&
        store.state.auth.user.id
      ) {
        this.send(
          new WebSocketRequest(WebSocketAction.CORE_INIT, {
            userId: store.state.auth.user.id,
          }),
        );
      }

      /**
       * If something is left in the buffer
       */
      while (this.dataBuffer.length > 0) {
        const elem: WebSocketRequest = this.dataBuffer.shift();
        this.send(elem);
      }
    });

    // Listen for messages
    this.socket.addEventListener('message', (event: MessageEvent) => {
      // console.log('[WS] Message from server ', event.data);

      const response: WebSocketRequest = JSON.parse(event.data);

      if (response && response.action) {
        WebSocketEventBus.$emit(response.action, response.payload);
      } else {
        WebSocketEventBus.$emit('core.response', response);
      }

      WebSocketEventBus.$emit(WebSocketAction.NOTIFICATION_SEND, response);
    });

    // Connection closed on server side
    this.socket.addEventListener('close', (event: CloseEvent) => {
      WebSocketEventBus.$emit(WebSocketAction.CORE_CONNECTION_CLOSED);
      console.log('[WS] Connection closed - restart', event);
      if (event.wasClean) {
        this.clearHealthCheckInterval();
      } else {
        this.reconnect();
      }
    });

    // Error on server side
    this.socket.addEventListener('error', (event: Event) => {
      WebSocketEventBus.$emit(WebSocketAction.CORE_CONNECTION_ERROR);
      console.log('[WS] Connection error', event);
    });
  }

  protected send(msg: WebSocketRequest) {
    if (!this.isCreated()) {
      console.log('[WS] Message queued. WebSocket not created.');
      this.putIntoBuffer(msg);
      return;
    }

    if (!this.isOpen() && this.isConnecting()) {
      console.log('[WS] Message queued. WebSocket created but not opened.');
      this.putIntoBuffer(msg);
      return;
    }
    this.socket.send(JSON.stringify(msg));
  }

  protected initializeHealthCheck() {
    if (!this.healthCheckInterval) {
      this.healthCheckInterval = setInterval(() => {
        this.sendHealthCheck();
      }, this.HEALTHCHECK_TIMEOUT * 1000);
    }
  }

  protected async sendHealthCheck() {
    const apiMesaage = new WebSocketRequest(WebSocketAction.CORE_HEALTH_CHECK);
    this.send(apiMesaage);
  }

  protected reconnect() {
    this.clearHealthCheckInterval();
    setTimeout(() => {
      this.close();
      this.initConnection();
    }, this.RECONNECT_DELAY * 1000);
  }

  protected clearHealthCheckInterval() {
    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
      this.healthCheckInterval = undefined;
    }
  }

  protected isCreated(): boolean {
    return !!this.socket;
  }

  protected isOpen(): boolean {
    if (!this.isCreated()) {
      return false;
    }

    return this.socket.readyState === WebSocket.OPEN;
  }

  protected isConnecting(): boolean {
    if (!this.isCreated()) {
      return false;
    }

    return this.socket.readyState === WebSocket.CONNECTING;
  }

  protected putIntoBuffer(msg: WebSocketRequest) {
    if (msg.action !== WebSocketAction.CORE_HEALTH_CHECK) {
      this.dataBuffer.push(msg);
    }
  }
}
