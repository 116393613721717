import { RootStateInterface } from './rootState';
import Vue from 'vue';
import Vuex from 'vuex';
import { core } from 'modules/core/stores/core';
import { auth } from 'modules/auth/stores/auth';
import { brokers } from 'modules/brokers/store/brokers';
import { personalAssistants } from 'modules/personalAssistant/store/personalAssistant';
import createPersistedState from 'vuex-persistedstate';
import { notifications } from '@/modules/notifications/store/notification';
import { clientsList } from '@/modules/clientslist/stores/clientsList';
Vue.use(Vuex);

export default new Vuex.Store<RootStateInterface>({
  state: {
    version: '1.0.0',
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    core,
    auth,
    brokers,
    personalAssistants,
    notifications,
    clientsList,
  },
});
