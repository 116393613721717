import { RootStateInterface } from '@/store/rootState';
import { MutationTree, Module } from 'vuex';
import { BrokerStateInterface } from '../interfaces/brokerState';
import { File } from '@/modules/clients/models/File';

export const state: BrokerStateInterface = {
  brokerId: null,
  company: {
    id: null,
    owner: null,
    name: null,
    logo: null,
    featureFlags: null,
  },
};

export const mutations: MutationTree<BrokerStateInterface> = {
  updateBrokerId(oldState: BrokerStateInterface, payload: number | null) {
    oldState.brokerId = payload;
  },

  update(oldState: BrokerStateInterface, payload: BrokerStateInterface) {
    oldState.company.id = payload?.company?.id ?? null;
    oldState.company.owner = payload?.company?.owner ?? null;
    oldState.company.name = payload?.company?.name ?? null;
    oldState.company.logo = payload?.company?.logo ?? null;
    oldState.company.featureFlags = payload?.company.featureFlags ?? null;
    oldState.brokerId = payload?.brokerId ?? null;
  },

  updateLogo(oldState: BrokerStateInterface, payload: File | null) {
    oldState.company.logo = payload;
  },
};

const namespaced: boolean = true;

export const brokers: Module<BrokerStateInterface, RootStateInterface> = {
  namespaced,
  state,
  mutations,
};
